<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-20 14:42:31
 * @LastEditors: ------
 * @LastEditTime: 2021-10-27 11:09:47
-->
<template>
  <div class="serveProject_container">
    <div class="serveProject_banner">
      <img src="@/assets/joinUs/banner.png" alt="" />
    </div>
    <div class="sticky">
      <van-tabs
        title-active-color="#F58B1C"
        swipeable
        animated
        border
        sticky
        :ellipsis="false"
        color="#F58B1C"
        v-model="num.id"
        @change="change"
      >
        <van-tab v-for="(item, index) in tab" :title="item" :key="index">
          <div class="zhaoRen" v-show="num.id == 0">
            <div class="con_title">
              {{ item }}
            </div>
            <div class="email">
              <img src="../../assets/joinUs/email1.png" alt="" />
              如有意向请发送简历到:zhaopin@aijiehu.cn
            </div>
            <div class="join_box ">
              <div>
                <p>岗位职责：</p>
                <p>
                  1.
                  负责全国销售计划及目标达成：组织编制年度销售计划及费用、指标拆分，薪酬绩效，销售利润、人效、收入等目标达成。
                </p>
                <p>
                  2.
                  负责销售团队管理工作：制定及实施门店扩张计划、招聘、任命及选拔各大区的门店经理、销售经理、销售人员等。
                </p>
                <p>
                  3.
                  建立与完善联营、加盟、合作伙伴管理体系：通过政策、流程和系统，提供政策支持和服务建立联营、加盟、合作门店的运作体系、营运手册。
                </p>
                <p>
                  4.
                  确保公司的各项政策在区域联营、加盟、合作门店得到有效落实：对各门店的经营操作进行监督指引，并给予有效建议方案，为其提供相应的运作体系化培训。
                </p>
                <p>
                  5.
                  制定和完善各项销售政策和制度：做好风险管控，保证公司各项销售政策的有效宣贯和落实，按照评估标准对其进行考核与检查，促进销售管理规范化、制度化。
                </p>
              </div>
              <div>
                <p>任职资格：</p>
                <p>
                  1.
                  本科及以上学历，45岁以下。零售行业联营、加盟、合作管理5年以上经验。
                </p>
                <p>
                  2.
                  体系化建设能力，有过体系化的管理经验，熟悉联营、加盟、合作商的生意模式。
                </p>
                <p>
                  3.
                  数据分析能力，经营诊断与管理洞察；优秀的总结提炼与结构化文档呈现。
                </p>
                <p>4. 系统与结构化思维，不同利益者的沟通协调。</p>
              </div>
            </div>
            <div class="join_box join_box1">
              <div>
                <p>岗位职责：</p>

                <p>1. 负责区域内相关养老项目的开拓、跟进。</p>
                <p>
                  2.
                  为各护理站进行相关地推业务的开发销售，完成业绩指标及后期维护。
                </p>
                <p>3. 招投标参与及合同洽谈。</p>
                <p>4. 完成上级领导交办的其他工作。</p>
              </div>
              <div>
                <p>任职资格：</p>
                <p>
                  1. 专科以上学历，社会服务、市场营销等专业优先考虑。
                </p>
                <p>2. 具有3年以上地推面销经验。</p>
                <p>3. 有民政及政府关系渠道资源优先考虑。</p>
                <p>4. 热爱养老、医疗、健康行业，对老人有足够的耐心和爱心。</p>
                <p>
                  5. 善于与街道、居委等社区行政管理机构沟通并维护良好的关系。
                </p>
              </div>
            </div>
            <div class="join_box join_box2">
              <div>
                <p>岗位职责：</p>
                <p>1.了解国家养老相关政策法规，并能积极学习。</p>
                <p>
                  2.定期与街道职业中心，街道社区事务中心等联系，获取目标客户相关信息。
                </p>
                <p>3.收集市场同行业信息，拓展公司业务。</p>
              </div>
              <div>
                <p>任职资格：</p>
                <p>
                  1. 懂上海话优先考虑。
                </p>
                <p>
                  2.
                  有街道职业中心工作，老年保健销售，社区超市工作等经历者优先。
                </p>
              </div>
            </div>
            <div class="join_box join_box3">
              <div>
                <p>岗位职责：</p>
                <p>1.能独立实施常见病、慢性病等康护指导与健康服务。</p>
                <p>2.负责实施各类康护服务质量检查，整理并汇总问题。</p>
                <p>
                  3.分析各类服务问题，制定提升方案，并跟进落实，确保问题有效解决。
                </p>
                <p>4.制定品控方案，能持续改进，推动整体服务质量提升。</p>
                <p>5.具备较高的风险防范意识，制定风险防控措施。</p>
              </div>
              <div>
                <p>任职资格：</p>
                <p>
                  1.学历背景： 大中专及以上学历，临床或护理相关专业优先考虑。
                </p>
                <p>
                  2.工作经验与资历：
                  五年以上临床及健康服务行业的经验，有管理经验和护理经验者优先。
                </p>
                <p>
                  3. 基本技能和素质： ① 良好的服务与沟通意识。 ②
                  较强的思维逻辑与学习能力、沟通协调能力、分析判断能力、执行力。
                  ③ 熟练使用OFFICE办公软件、较强的方案及文档制作能力。 ④
                  了解项目管理的相关知识。
                </p>
                <p>4、有主管护师证者优先。</p>
              </div>
            </div>
            <div class="join_box join_box4">
              <div>
                <p>岗位职责：</p>
                <p>
                  1.
                  负责通过相关渠道招聘持有护理员证或家政服务证或有意愿从事医疗照护/家政服务的服务人员。
                </p>
                <p>2. 负责开发和维护与各家家政机构、护理机构的合作关系。</p>
                <p>
                  3. 维护好护理员/家政服务员的关系和资源，做好老带新的招生循环。
                </p>
                <p>4. 安排护理员/家政服务员的面试、心理疏导、关系维护等。</p>
                <p>5. 护理员/家政服务员的资源维护。</p>
              </div>
              <div>
                <p>任职资格：</p>
                <p>
                  1.年龄 25-45 岁， 中专以上学历，大专学历以上优先。
                </p>
                <p>
                  2.从事医护、家政招生/派单工作两年以上经验，有大型护理机构、大型家政机构工作经验的优先。
                </p>
                <p>3.了解医护、家政业务逻辑，了解如何维系招生关系。</p>
                <p>
                  4.沟通洽谈能力良好，了解护理员、家政服务员的生活工作状态和心理需求。
                </p>
                <p>5.普通话流利，有一定的护理技能或经验，会基础的电脑操作。</p>
              </div>
            </div>
            <div class="join_box join_box5">
              <div>
                <p>岗位职责：</p>
                <p>1. 按照要求为老年人提供生活料理。</p>
                <p>2. 和老年人进行基本沟通以及精神生活的慰藉。</p>
                <p>3. 热爱养老行业，遵守管理制度，服从工作安排。</p>
              </div>
              <div>
                <p>任职资格：</p>
                <p>
                  1. 年龄18-60周岁，男女不限，身体健康。
                </p>
                <p>2. 服务意识强，工作认真负责，语言表达清楚。</p>
                <p>3. 有医疗照护证、养老护理证更佳。</p>
              </div>
            </div>
            <div class="email2">
              <img src="../../assets/joinUs/email2.png" alt="" />
              如有意向请发送简历到:zhaopin@aijiehu.cn
            </div>
          </div>
          <div class="zhaoRen heZuo" v-show="num.id == 1">
            <div class="con_title">
              {{ item }}
            </div>
            <div class="hezuo_font">
              <p>
                爱介护始终致力于为社区居家长者提供方便、快捷、高质量、人性化的健康介护服务，并逐步成为国内居家养老行业独具特色的品牌和服务连锁机构。主要服务项目有长护险服务、健康管家、生活照料、医院陪护、康复理疗、乐养生活、老年餐定制配送、中医按摩、钟点服务、乐养服务以及适老化改造等。
              </p>
              <p>
                我们长期接受各行各业的异业联盟合作，欢迎致电<b>400-180-1219</b>洽谈合作事宜。
              </p>
              <img class="imgs" src="../../assets/joinUs/hezuo.png" alt="" />
              <div class="hezuo_bottom"></div>
              <div class="hz_box">
                <div class="hz_box_con">
                  <div class="_con_top">加盟合作</div>
                  <div class="infos">
                    <div class="info">
                      <span>姓名</span>
                      <input
                        type="text"
                        placeholder="请输入姓名"
                        v-model="nameValue"
                      />
                    </div>
                    <div class="info">
                      <span>地址</span>
                      <input
                        type="text"
                        placeholder="请输入地址"
                        v-model="addressValue"
                      />
                    </div>
                    <div class="info">
                      <span>手机</span>
                      <input
                        type="text"
                        placeholder="请输入手机号"
                        v-model="phoneValue"
                      />
                    </div>
                    <div class="info">
                      <span>邮箱</span>
                      <input
                        type="text"
                        placeholder="请输入邮箱"
                        v-model="emailValue"
                      />
                    </div>
                    <div class="liuYan">
                      <span>留言</span>
                      <!-- <textarea></textarea> -->
                      <textarea name="" id="advice—textarea"></textarea>
                    </div>
                    <button class="submit" @click="submit">
                      提交信息
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="zhaoRen zhaoMu" v-show="num.id == 2">
            <div class="con_title">
              {{ item }}
            </div>
            <div class="zhaoMu_font">
              <p>
                志愿者服务是一项崇高的社会公益活动，是城市文明进步的重要标志。为进一步弘扬“奉献，友爱，互助，进步”的志愿精神，促进城市文明和社区建设，爱介护现面向社会招募社区活动支援者。
              </p>
              <img src="../../assets/joinUs/zhaomu.png" alt="" />
            </div>

            <div class="join_box ">
              <div>
                <p>1.年满18岁，具有完全的民事行为能力、民事责任能力</p>
                <p>2.乐于奉献，热爱志愿服务等公益事业</p>
                <p>3.身体健康，具有吃苦耐劳精神</p>
                <p>4.遵纪守法，无违纪行为，服从安排，具有较强的社会公德意识</p>
              </div>
            </div>
            <div class="join_box join_box1">
              <div>
                <p>1.积极传播志愿服务理念，弘扬志愿服务精神的义务</p>
                <p>2.遵守社区志愿者组织的各项政策和规章制度</p>
                <p>3.履行经本人签署的志愿服务承诺书的义务；</p>
                <p>4.服务社区志愿者组织的指挥和调配。认真完成志愿服务工作</p>
                <p>5.服从志愿服务期间所在团队管理的义务</p>
              </div>
            </div>
            <div class="join_box join_box2">
              <div>
                <p>电话：400-180-1219</p>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { commitMsg } from "../../utils/request";
export default {
  name: "aboutUs",

  data() {
    return {
      tab: ["招贤纳士", "异业合作", "志愿者招募"],
      num: {
        id: this.$store.state.parent, // 首次从vuex中获取
      },
      nameValue: "",
      phoneValue: "",
      addressValue: "",
      emailValue: "",
    };
  },
  computed: {
    listData() {
      return this.$store.state.parent;
    },
  },
  created() {},
  methods: {
    change(e) {
      this.num.id = e;
      this.$store.commit("setPartner", e); // 存放在状态管理
    },
    submit() {
      var re = /^1[1356789]\d{9}$/;
      var re1 = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      let str1 = this.emailValue;
      let str = this.phoneValue;
      var text = document.getElementById("advice—textarea").value;
      if (
        re.test(str) &&
        re1.test(str1) &&
        this.nameValue != "" &&
        this.addressValue != ""
      ) {
        commitMsg({
          applyPerson: this.nameValue,
          applyMobile: this.phoneValue,
          applyAddress: this.addressValue,
          applyEmail: this.emailValue,
          applyMark: text,
          applyType: 1,
        }).then(
          (res) => {
            console.log(res);
            this.$dialog
              .alert({
                message: "提交成功！",
                theme: "round-button",
              })
              .then(() => {
                (this.nameValue = ""),
                  (this.phoneValue = ""),
                  (this.addressValue = ""),
                  (this.emailValue = ""),
                  (text = "");
              });
          },
          (err) => {
            console.log(err);
          }
        );
      } else {
        this.$dialog
          .alert({
            message: "请检查、补全信息！",
            theme: "round-button",
          })
          .then(() => {
            // on close
          });
      }
    },
  },
  watch: {
    listData(newVal) {
      // 监听vuex 数据变化 ， 如果改变就重新赋值
      this.num.id = newVal;
    },
  },
};
</script>

<style scoped lang="less">
.serveProject_container {
  .serveProject_banner {
    img {
      width: 100%;
    }
  }
  .con_title {
    text-align: center;
    color: #f58b1c;
    font-weight: bold;
    font-size: 22px;
    padding: 30px 0 26px 0;
  }
  .zhaoRen {
    padding: 0 16px 20px 16px;
    background: #f7faff;
    .email {
      font-size: 16px;
      color: #333333;
      line-height: 26px;
      background: #ffffff;
      border: 1px solid #707070;
      border-radius: 4px;
      padding: 38px 12px 18px 12px;
      margin: 30px 0;
      position: relative;
      img {
        position: absolute;
        width: 110px;
        height: 62px;
        top: -30px;
        left: -0px;
      }
    }
    .email2 {
      font-size: 16px;
      color: #333333;
      line-height: 26px;
      background: #ffffff;
      border: 1px solid #707070;
      border-radius: 4px;
      padding: 38px 12px 18px 12px;
      margin-top: 60px;
      position: relative;
      img {
        position: absolute;
        width: 110px;
        height: 62px;
        top: -30px;
        right: 0;
      }
    }
    .join_box {
      border: 1px solid rgb(138, 136, 136);
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      margin-top: 10px;
      padding-top: 40px;
      &::before {
        content: "销售总监";
        position: absolute;
        background: url("../../assets/joinUs/yellow.png") no-repeat;
        background-size: contain;
        width: 180px;
        height: 46px;
        top: -8px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        font-weight: 500;
      }
      div {
        padding: 14px 12px;
        p {
          color: #333333;
          line-height: 26px;
          font-size: 16px;
        }
      }
    }
    .join_box1 {
      margin-top: 40px;
      &::before {
        content: "门店销售经理";
      }
    }
    .join_box2 {
      margin-top: 40px;
      &::before {
        content: "销售代表";
      }
    }
    .join_box3 {
      margin-top: 40px;
      &::before {
        content: "品控主管/专员";
      }
    }
    .join_box4 {
      margin-top: 40px;
      &::before {
        content: "家政护理招生老师";
      }
    }
    .join_box5 {
      margin-top: 40px;
      &::before {
        content: "养老护理专员(护工)";
      }
    }
  }
  .heZuo {
    padding: 0;
    .hezuo_font {
      color: #333333;
      line-height: 26px;
      font-size: 16px;
      p {
        padding: 0 16px;
        margin-bottom: 10px;
        b {
          color: #ff9500;
        }
      }
      .imgs {
        padding: 0 16px;
        width: 343px;
        height: 196px;
        margin-top: 6px;
      }
      .hezuo_bottom {
        position: relative;
        margin: 90px 0 90px 0;
        height: 168px;
        background: linear-gradient(89deg, #ffa662 0%, #f5ae37 100%);
      }
      .hz_box {
        position: absolute;
        top: 570px;
        height: 270px;
        width: 343px;
        margin-left: 16px;
        background-color: #fff;
        box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
        text-align: center;

        .hz_box_con {
          width: 100%;
          ._con_top {
            margin: auto;
            color: #333333;
            font-size: 18px;
            line-height: 26px;
            width: 76px;
            padding-bottom: 4px;
            margin-top: 16px;
            font-weight: 600;
            border-bottom: 3px solid #fdb02a;
          }
          .infos {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin-top: 20px;
            .info {
              margin-bottom: 12px;
              height: 28px;
              line-height: 28px;
              font-size: 13px;
              input {
                width: 100px;
                margin-left: 10px;
                padding-left: 4px;
                border: none;
                border: 1px solid #ccc;
                border-radius: 4px;
              }
            }
            .liuYan {
              font-size: 13px;
              span {
                position: relative;
                top: -46px;
              }
              textarea {
                width: 264px;
                max-width: 264px;
                margin-left: 10px;
                height: 60px;
                border: none;
                border: 1px solid #ccc;
                border-radius: 4px;
                text-indent: 2em;
              }
            }
            .submit {
              border: none;
              width: 100px;
              height: 26px;
              background: #f5ae38;
              border-radius: 2px;
              font-size: 12px;
              color: #ffffff;
              margin-top: 10px;
              &:active {
                box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.432);
              }
            }
          }
        }
      }
    }
  }
  .zhaoMu {
    .zhaoMu_font {
      color: #333333;
      line-height: 26px;
      font-size: 16px;
      img {
        width: 342px;
        height: 200px;
        margin: 24px 0;
      }
    }
    .join_box {
      &::before {
        content: "志愿者基本条件";
      }
    }
    .join_box1 {
      margin-top: 40px;
      &::before {
        content: "社区志愿者的职责";
      }
    }
    .join_box2 {
      margin-top: 40px;
      text-align: center;
      &::before {
        content: "报名方式";
      }
    }
  }
  /deep/ .van-tab {
    font-size: 16px;
  }
  /deep/ .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0 0 1px 0;
    border-color: var(--color666);
  }
  /deep/ .van-sticky--fixed {
    top: 56px;
  }
}
</style>
